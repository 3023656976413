.App-header{
  position: relative;
}

.App-logo {
  position: absolute;
  z-index: 1;
  top: 10px;
  left: 20px;
  width: 325px;
}

.beeHeader{
  width: 100%;
}

video{
  width: 100%;
}

.row{
  display: flex;
  align-content: space-around;
  flex-flow: row wrap;
}

.plain-txt{
  margin: 30px;
}

.row1{
  margin: 50px auto 0;
  background-color: white;
}

.row-items{
  width: 45%;
}

.color-bar .row-items{
  width: 20%;
  height: 100px;
  text-align: center;
  font-weight: bold;
}

.row-content{
  color: #353a47;
  text-align: center;
  font-weight: bold;
  font-size: 1em;
  padding: 10px;
  margin: auto;
}

.row-img{
  width: 100%;
  overflow: hidden;
}

.moodBoard{
  line-height: 1.4;
  font-family: 'Merriweather', serif;
  font-size: 1em;
}

.yellow{
  background-color: #dc8d2c;
  color: #e3e3e3;
}

.white{
  background-color: #e3e3e3;
  color: #353a47;
}

.green{
  background-color: #5da271;
  color: #e3e3e3;
}

.red{
  background-color: #885a5a;
  color: #e3e3e3;
}

.black{
  background-color: #353a47;
  color: #e3e3e3;
}


@media (min-width: 800px) {
  .App{
    width: 800px;
    margin: auto;
  }

  .row-content{
    font-size: 2em;
  }
}